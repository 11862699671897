<template>
  <form class="login-form" @submit.prevent="passwordRecovery">
    <div class="mb-0">
      <div class="card-body">
        <div class="text-center mb-3">
          <div
            class="d-inline-flex align-items-center justify-content-center my-2"
          >
            <img
              src="@/assets/img/horizon_logo_color.png"
              class="h-72px"
              alt="Logo do Portal do Cliente"
            />
          </div>
          <h5 class="mb-0">Portal do Cliente</h5>
          <span class="text-muted">Recuperar Senha</span>
        </div>
        <div class="mb-3">
          <label class="form-label">E-mail</label>
          <div class="form-control-feedback form-control-feedback-start">
            <input
              type="text"
              class="form-control"
              :class="{ 'is-invalid': v$.email.$errors.length }"
              placeholder="usuario@email.com"
              v-model="v$.email.$model"
            />
            <div class="form-control-feedback-icon">
              <i class="ph-envelope text-muted" />
            </div>
            <span v-if="v$.email.required.$invalid" class="invalid-feedback">
              O campo é obrigatório
            </span>
            <span v-if="v$.email.email.$invalid" class="invalid-feedback">
              Formato de e-mail inválido
            </span>
          </div>
        </div>
        <div class="mb-3">
          <button
            type="submit"
            class="btn btn-primary w-100"
            title="Recuperar Senha"
            :disabled="v$.$invalid || disableButton"
          >
            <span v-if="disableButton">
              <i class="ph-spinner spinner" />
            </span>
            <span v-else>Recuperar Senha</span>
          </button>
        </div>
        <div class="text-center" title="Login">
          <router-link to="/">Login</router-link>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import { useVuelidate } from '@vuelidate/core'
import { required, email } from '@vuelidate/validators'

export default {
  name: 'PasswordRecovery',

  setup() {
    return { v$: useVuelidate() }
  },

  data() {
    return {
      email: '',
      disableButton: false,
    }
  },

  methods: {
    async passwordRecovery() {
      if (!this.v$.$invalid) {
        this.disableButton = true
        try {
          const response = await this.$store.dispatch(
            'passwordRecovery',
            this.email,
          )

          this.resetForm()

          if (response && response.message) {
            this.$root.$refs.notification.success(response.message)
          } else {
            this.$root.$refs.notification.error(
              'Não foi possível se conectar ao servidor.',
            )
          }
        } catch (error) {
          this.resetForm()

          if (error && error.message) {
            this.$root.$refs.notification.warning(error.message)
          } else {
            this.$root.$refs.notification.error(
              'Não foi possível se conectar ao servidor.',
            )
          }
        }
      }
    },

    resetForm() {
      this.disableButton = false
      this.email = ''
      this.v$.$reset()
    },
  },

  validations() {
    return {
      email: {
        required,
        email,
      },
    }
  },
}
</script>
